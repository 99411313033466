<template>
  <div id="test">
    <h1 class="title">Génération de votre pdf</h1>
    <h1 class="subtitle">Référence: {{ getQuoteToken }}</h1>


    <div class="progress-container">
      <!--<div class="ico animated">

        <div class="circle circle-top"></div>
        <div class="circle circle-main"></div>
        <div class="circle circle-bottom"></div>

        <svg class="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 612 612"
             style="enable-background:new 0 0 612 612;" xml:space="preserve">
          <defs>
            <clipPath id="cut-off-arrow">
              <circle cx="306" cy="306" r="287"/>
            </clipPath>

            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                             result="goo"/>
              <feBlend in="SourceGraphic" in2="goo"/>
            </filter>

          </defs>
          <path class="st-arrow" d="M317.5,487.6c0.3-0.3,0.4-0.7,0.7-1.1l112.6-112.6c6.3-6.3,6.3-16.5,0-22.7c-6.3-6.3-16.5-6.3-22.7,0
                  l-86,86V136.1c0-8.9-7.3-16.2-16.2-16.2c-8.9,0-16.2,7.3-16.2,16.2v301.1l-86-86c-6.3-6.3-16.5-6.3-22.7,0
                  c-6.3,6.3-6.3,16.5,0,22.7l112.7,112.7c0.3,0.3,0.4,0.7,0.7,1c0.5,0.5,1.2,0.5,1.7,0.9c1.7,1.4,3.6,2.3,5.6,2.9
                  c0.8,0.2,1.5,0.4,2.3,0.4C308.8,492.6,313.8,491.3,317.5,487.6z"/>
        </svg>
      </div> -->

      <b-progress type="is-link" size="is-medium" :value="getQuoteProgress.currentValue" show-value format="percent"
                  style="width: 50%"></b-progress>

    </div>

    <div style="margin-top: 25px; font-size: 24px">
      <p v-if="getQuoteProgress.currentStep === 0" style="color: orangered"><b>Une erreur est survenue</b></p>
      <p v-else-if="getQuoteProgress.currentStep === 1">Génération du pdf...</p>
      <p v-else-if="getQuoteProgress.currentStep === 2">Envoi du pdf au serveur...</p>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "QuoteGeneration",
  computed: {
    ...mapGetters("quote", ["getQuoteToken", "getQuoteProgress"]),
  },

  props: {
    goodies: Boolean
  },

  mounted() {
    //this.proceedPdfGenerate()
    this.$store.commit('quote/setQuoteProgress', {
      steps: 2,
      currentStep: 0,
      value: 0,
      running: false,
      currentValue: 0
    })

    this.$store.commit('quote/incrProgressStep')

    this.$store.dispatch('quote/generatePdf', this.goodies).then(result => {

      if (result === undefined) {
        this.pdfError()
        return
      }

      let file = result.output('datauristring')

      setTimeout(() => {
        this.$store.commit('quote/setQuoteProgressCurrentValue', 50)
      }, 1000)

      setTimeout(() => {
        this.$store.commit('quote/incrProgressStep')
        this.$store.dispatch('quote/sendEmail', file).catch(() => {
          this.pdfError()
        })
      }, 2000)

    }).catch(e => {
      this.pdfError(e)
    })

  },
  methods: {

    pdfError(e) {
      this.$buefy.snackbar.open({
        message: 'Une erreur est survenue lors de la génération du pdf' + (e !== undefined ? ': (' + e.message + ')' : '')
            + '<br>Veuillez vérifiez vos informations et réessayer.'
            + '<br>Si le problème persiste, veuillez <a class="is-info" href="mailto:paul@fanabriques.fr" target="_blank">contacter le webmaster</a>',
        type: 'is-danger',
        duration: 7500
      })

      this.$store.commit('quote/cancelProgress')
      this.$store.commit('quote/decrementStatus')
    }
  }
}
</script>

<style scoped>

.progress-container {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center
}

.ico {
  margin: 0 auto;
  text-align: center;
  width: 100px;
  height: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  filter: url("#goo");
}

.ico .circle {
  background: lightslategray;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 0%;
}

.ico .circle.circle-top {
  height: 33.3333333333px;
  width: 41.6666666667px;
  -webkit-animation: blob-1-anim 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: blob-1-anim 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  z-index: 0;
  top: 0;
}

.ico .circle.circle-bottom {
  height: 33.3333333333px;
  width: 41.6666666667px;
  -webkit-animation: blob-2-anim 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: blob-2-anim 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  z-index: 9;
  bottom: 0px;
}

.ico .st-arrow {
  fill: #55544F;
  -webkit-animation: scrolly 3s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
  animation: scrolly 3s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
  perspective: 9000px;
  transform: translateZ(0);
  transform-origin: bottom;
}

.svg {
  z-index: 9;
  position: relative;
}

@-webkit-keyframes blob-1-anim {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  14% {
    transform: translateX(-50%) translateY(-8px);
  }
  24% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes blob-1-anim {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  14% {
    transform: translateX(-50%) translateY(-8px);
  }
  24% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@-webkit-keyframes blob-2-anim {
  0% {
    transform: scale(1) translate(-50%, 10px);
  }
  30% {
    transform: scale(1) translate(-50%, 10px);
  }
  70% {
    transform: scale(1) translate(-50%, 10px);
  }
  95% {
    transform: scale(1) translate(-50%, 26px);
  }
  100% {
    transform: scale(1) translate(-50%, 10px);
  }
}

@keyframes blob-2-anim {
  0% {
    transform: scale(1) translate(-50%, 10px);
  }
  30% {
    transform: scale(1) translate(-50%, 10px);
  }
  70% {
    transform: scale(1) translate(-50%, 10px);
  }
  95% {
    transform: scale(1) translate(-50%, 26px);
  }
  100% {
    transform: scale(1) translate(-50%, 10px);
  }
}

@-webkit-keyframes scrolly {
  0% {
    transform: translate3d(0, -150%, 0) rotateX(90deg) scale(0.5) skewX(3deg);
  }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  70% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  95% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
  100% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
}

@keyframes scrolly {
  0% {
    transform: translate3d(0, -150%, 0) rotateX(90deg) scale(0.5) skewX(3deg);
  }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  70% {
    transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
  }
  95% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
  100% {
    transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5) skewX(-3deg);
  }
}
</style>