<template>
  <div>
    <div class="w-title">
      <h1 class="title">{{goodies ? 'Commande goodies' : 'Devis estimatif'}}</h1>
      <p><b style="color: orangered">IMPORTANT</b> : Le document n'est pas un contrat, il s'agit d'un préalable à
        l'envoi d'un devis selon la disponibilité des articles</p>
    </div>

    <hr class="solid">

    <b-steps v-model="status" :has-navigation="false" size="is-small" v-if="status < 5">
      <b-step-item :label="!goodies ? 'Transport aller' : 'Récupération'" icon="truck-fast-outline"></b-step-item>
      <b-step-item v-if="!goodies" label="Transport retour" icon="truck-fast-outline"></b-step-item>
      <b-step-item v-if="!goodies" label="Durée" icon="calendar-clock"></b-step-item>
      <b-step-item label="Infos personnelles" icon="clipboard-account-outline"></b-step-item>
      <b-step-item label="Validation" icon="clipboard-check"></b-step-item>
    </b-steps>

    <div class="quote-content">

      <transition name="fade" v-if="!goodies">
        <quote-transport v-if="status === 0 || status === 1" :direction="status === 1"
                         :goodies="false"></quote-transport>
        <quote-duration v-if="status === 2"></quote-duration>
        <quote-infos v-if="status === 3" :goodies="false"></quote-infos>
        <quote-validation v-if="status === 4"></quote-validation>
        <quote-generation v-if="status === 5" :goodies="false"></quote-generation>
        <quote-success v-if="status === 6"></quote-success>
      </transition>

      <transition name="fade" v-else>
        <quote-transport v-if="status === 0" :direction="false" :goodies="true"></quote-transport>
        <quote-infos v-if="status === 1" :goodies="true"></quote-infos>
        <quote-validation v-if="status === 2"></quote-validation>
        <quote-generation v-if="status === 3" :goodies="true"></quote-generation>
        <quote-success v-if="status === 4"></quote-success>
      </transition>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteTransport from "@/components/quote/QuoteTransport";
import QuoteDuration from "@/components/quote/QuoteDuration";
import QuoteInfos from "@/components/quote/QuoteInfos";
import QuoteGeneration from "@/components/quote/QuoteGeneration";
import QuoteValidation from "@/components/quote/QuoteValidation";
import QuoteSuccess from "@/components/quote/QuoteSuccess";

export default {
  name: "Quote",
  components: {QuoteSuccess, QuoteValidation, QuoteGeneration, QuoteInfos, QuoteDuration, QuoteTransport},
  computed: {
    ...mapGetters("cart", ["itemsCount", "getItems", "getTotalPrice", "getCautionPrice", "needsMemberCard", "hasMemberCard"]),
    ...mapGetters("quote", ["getStatus"]),

    status: {
      get() {
        return this.getStatus
      },

      set(value) {
        this.$store.commit('quote/setStatus', value)
      }
    },
  },
  data() {
    return {
      goodies: false
    }
  },
  mounted() {
    let items = this.getItems
    for (const itemsKey in items) {
      let item = items[itemsKey]
      if (item.category_id !== process.env.VUE_APP_GOODIES_CATEGORY_ID) continue
      this.goodies = true
      return
    }
    this.goodies = false
  },
  methods: {}
}
</script>

<style scoped>
.custom-breadcrumb {
  display: flex;
  justify-content: center;
}

.custom-breadcrumb a:disabled {
  color: gray;
  cursor: default;
}

.quote-content {
  margin: 10px;
  padding: 5px;
}

</style>