<template>
  <table class="table is-bordered">
    <thead>
    <tr>
      <td><b>Description</b></td>
      <td><b>Valeur</b></td>
      <td><b>Total (€)</b></td>
    </tr>
    </thead>
    <tbody>

    <tr>
      <td>Départ</td>
      <td>
        {{ direction ? callback.destinationAddress : callback.originAddress }}
      </td>
      <td>-</td>
    </tr>

    <tr>
      <td>Destination</td>
      <td>
        {{ direction ? callback.originAddress : callback.destinationAddress }}
      </td>
      <td>-</td>
    </tr>

    <tr>
      <td>Distance</td>
      <td>{{ callback.distance.text }}</td>
      <td>{{ ((transportData.kmPrice / 1000) * callback.distance.value).toFixed(2) }}€</td>
    </tr>

    <tr>
      <td>Temps de trajet</td>
      <td>{{ callback.duration.text }}</td>
      <td>{{ (transportData.hourPrice * (callback.duration.value / 3600)).toFixed(2) }}€</td>
    </tr>

    <tr v-if="type !== 'anim'">
      <td>Total (arrondi)</td>
      <td>x 2 (allez-retour)</td>
      <td><b>{{Math.ceil(((transportData.kmPrice / 1000) * callback.distance.value + transportData.hourPrice * (callback.duration.value / 3600)) * 2).toFixed(2) }}€</b></td>
    </tr>

    <tr v-else>
      <td>Total (arrondi)</td>
      <td>x {{trips}} allez-retour(s)</td>
      <td><b>{{trips * Math.ceil(((transportData.kmPrice / 1000) * callback.distance.value + transportData.hourPrice * (callback.duration.value / 3600)) * 2).toFixed(2) }}€</b></td>
    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  name: "QuoteTransportCallbackTable",
  props: {
    callback: {},
    direction: Boolean,
    transportData: {},
    type: String,
    trips: Number
  }
}
</script>

<style scoped>

</style>