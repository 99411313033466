<template>
  <div>

    <div class="quote-infos">
      <div>
        <p class="title">Infos</p>
        <b-field grouped position="is-centered">
          <b-field label="Prénom *">
            <b-input v-model="getUserInfos.firstName" icon="account" maxlength="64"></b-input>
          </b-field>

          <b-field label="Nom de famille *">
            <b-input v-model="getUserInfos.lastName" maxlength="64"></b-input>
          </b-field>
        </b-field>

        <b-field label="Adresse email *" position="is-centered"
                 :type="{ 'is-danger': emailError }"
                 :message="{ 'Email non valide': emailError }">
          <b-input v-model="getUserInfos.email" type="email" icon="email" maxlength="64"></b-input>
        </b-field>

        <b-field label="Numéro de téléphone" position="is-centered">
          <b-input v-model="getUserInfos.phone" type="tel" icon="phone" maxlength="64"></b-input>
        </b-field>

      </div>

      <div>
        <p class="title">Adresse de facturation</p>

        <b-field label="Nom de l'organisme" position="is-centered">
          <b-input icon="warehouse" v-model="getUserInfos.organizationName" maxlength="128"></b-input>
        </b-field>

        <b-field label="Numéro et nom de rue *" position="is-centered">
          <b-input icon="map-marker" v-model="getUserInfos.addressName" maxlength="128"></b-input>
        </b-field>

        <b-field grouped position="is-centered">
          <b-field label="Code postal et ville *">
            <b-input v-model="getUserInfos.addressCity" icon="city" maxlength="64"></b-input>
          </b-field>

          <b-field label="Pays *">
            <b-select placeholder="Choisir un pays" v-model="getUserInfos.addressCountry">
              <option v-for="country in getCountries"
                      :value="country"
                      :key="country">
                {{ country }}
              </option>
            </b-select>
          </b-field>
        </b-field>

      </div>
    </div>


    <p>* : Cases obligatoires</p>

    <hr class="solid"/>

    <div style="margin-top: 10px;" class="buttons is-centered">
      <b-button type="is-link is-light is-danger" icon-left="skip-previous" @click="previousStep">
        Retour à l'étape "{{ goodies ? 'Récupération' : 'Durée' }}"
      </b-button>

      <b-button type="is-link is-light" icon-left="skip-next" @click="nextStep" :disabled="empty">Passer à l'étape
        "Génération du pdf"
      </b-button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "QuoteInfos",
  data() {
    return {
      emailError: false
    }
  },
  computed: {
    ...mapGetters("quote", ["getUserInfos"]),
    ...mapGetters("data", ["getCountries"]),

    empty: {
      get() {
        for (let key in this.getUserInfos) {
          if (key === 'phone' || key === 'organizationName' || key === 'sendEmail' || key === 'message') continue;
          if (this.getUserInfos[key] !== '') continue;
          return true
        }

        return false;
      }
    },

  },

  created() {
    this.$store.dispatch("data/loadCountries")
  },

  props: {
    goodies: Boolean
  },

  methods: {
    nextStep() {

      if (!regex.test(this.getUserInfos.email)) {
        this.emailError = true
        return
      }


      if (this.getUserInfos.sendEmail === '') {
        this.getUserInfos.sendEmail = this.getUserInfos.email
      }

      this.emailError = false

      this.$store.commit('quote/incrementStatus')
    },

    previousStep() {
      this.$store.commit('quote/decrementStatus')
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  }
}
</script>

<style scoped>
.quote-infos {
  display: flex;
  justify-content: space-evenly;
}

.infos-form {
}

@media screen and (max-width: 1023px) {
  .quote-infos {
    flex-direction: column;
    align-items: center;
  }
}
</style>