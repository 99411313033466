<template>
  <div>
    <p>La durée de location fixe le prix en suivant des coefficients, que vous pouvez consulter <b><a
        @click="modalActive = true">en cliquant
      ici.</a></b></p>
    <p>Seuls les articles de type "matériel" sont affectés par ce coefficient.</p>

    <hr class="solid"/>

    <div>

      <b-message v-if="duration <= 0" type="is-danger" size="is-medium">
        Veuillez vérifier que la date de début soit avant la date de fin
      </b-message>

      <div class="quote-dates">
        <b-field label="Date de début">
          <b-datetimepicker
              placeholder="Entrez ou sélectionnez une date..."
              icon="calendar-today"
              v-model="getDates.startDate"
              :min-datetime="new Date()"
              inline>
          </b-datetimepicker>
        </b-field>

        <b-field label="Date de fin">
          <b-datetimepicker
              placeholder="Entrez ou sélectionnez une date..."
              icon="calendar-today"
              v-model="getDates.endDate"
              :min-datetime="getDates.startDate"
              inline>
          </b-datetimepicker>
        </b-field>

        <b-field label="Informations">
          <table class="table is-bordered" style="font-size: 20px">
            <thead>
            <tr>
              <td><b>Description</b></td>
              <td><b>Valeur</b></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Début</td>
              <td>{{ startDate.toLocaleString() }}</td>
            </tr>
            <tr>
              <td>Fin</td>
              <td>{{ endDate.toLocaleString() }}</td>
            </tr>
            <tr>
              <td>Durée</td>
              <td v-if="duration > 0">{{ duration }} jour{{ duration > 1 ? 's' : '' }}</td>
              <td v-else-if="duration <= 0">Erreur de dates</td>
            </tr>
            <tr>
              <td>Montant panier (1 jour)</td>
              <td>{{ price.toFixed(2) }} €</td>
            </tr>
            <tr v-if="coeff > 1">
              <td>Coefficient</td>
              <td>x {{ coeff }}</td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td><b>{{ (price * coeff).toFixed(2) }} €</b></td>
            </tr>
            </tbody>
          </table>
        </b-field>

      </div>

    </div>

    <hr class="solid"/>

    <div style="margin-top: 10px;" class="buttons is-centered">
        <b-button type="is-link is-light is-danger" icon-left="skip-previous" @click="previousStep">
          Retour à l'étape "Transport retour"
        </b-button>

      <b-button type="is-link is-light" icon-left="skip-next" @click="nextStep" :disabled="duration <= 0">Passer à
        l'étape "Infos personnelles"
      </b-button>
    </div>

    <b-modal
        v-model="modalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Afficher les coefficients"
        aria-modal>
      <template #default="props">
        <quote-duration-coeff-table :data="getDuration.coeffs" @close="props.close"></quote-duration-coeff-table>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteDurationCoeffTable from "@/components/quote/QuoteDurationCoeffTable";

export default {
  name: "QuoteDuration",
  components: {QuoteDurationCoeffTable},
  data() {
    return {
      coeff: 1,
      duration: 1,
      modalActive: false,
    }
  },

  computed: {
    ...mapGetters("cart", ["getTotalPriceByType"]),
    ...mapGetters("data", ["getDuration"]),
    ...mapGetters("quote", ["getDates"]),

    price: {
      get() {
        return this.getTotalPriceByType('materiel')
      }
    },

    startDate: {
      get() {
        return this.getDates.startDate
      },
      set(value) {
        return this.getDates.startDate = value
      }
    },

    endDate: {
      get() {
        return this.getDates.endDate
      },
      set(value) {
        return this.getDates.endDate = value
      }
    },

  },

  created() {
    this.$store.dispatch("data/loadCountries")
  },

  mounted() {
    this.recalculatePrice()
  },

  methods: {
    recalculatePrice() {
      this.duration = Math.ceil((this.endDate - this.startDate) / (1000 * 3600 * 24))
      let foundCoeff = 1

      for (const [key, value] of Object.entries(this.getDuration.coeffs)) {
        if (this.duration >= key) // days : coef
          foundCoeff = value
      }

      this.coeff = foundCoeff

    },

    nextStep() {

      if (this.duration <= 0) {

        this.$buefy.snackbar.open({
          message: 'Veuillez vérifier que la date de début soit avant la date de fin.',
          type: 'is-danger',
          duration: 5000
        })

        return
      }

      this.$store.commit('quote/incrementStatus')

    },

    previousStep() {
      this.$store.commit('quote/decrementStatus')
      window.scrollTo({top: 0, behavior: 'smooth'});
    },

  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    startDate: function (val, oldVal) {
      this.recalculatePrice()
    },
    // eslint-disable-next-line no-unused-vars
    endDate: function (val, oldVal) {
      this.recalculatePrice()
    },
  }
}
</script>

<style scoped>
.quote-dates {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

@media screen and (max-width: 1023px) {
  .quote-dates {
    flex-direction: column;
    align-items: center;
  }
}
</style>