<template>
  <div>
    <div class="select-transport-type">
      <div v-if="!goodies">
        <br>
        <p>Trois options sont disponibles : La récupération sur place ou la livraison et le retrait
          ou l'animateur s'occupe du transport.</p>
        <p>La livraison est possible dans la <b>limite du chargement d'une voiture</b>.</p>
      </div>
      <div v-else>
        Pour récupérer vos goodies :
        <br>
        Option 1 : vous passez au local de l'association Fanabriques. Les horaires vous seront précisés lors de la validation de la commande.
        <br>
        Option 2 : vous souhaitez un envoi Mondial Relay.
      </div>
      <div>
        <b-field :label="'Choix du type de transport ' + (direction ? 'retour' : 'aller')">
          <b-select id="transport-type-select" placeholder="Choisir un type" v-model="selectedType"
                    @input="iframeLoading = true">
            <option value="pickup" v-if="!direction">Récupération sur place</option>
            <option value="pickup" v-else>Retour sur place</option>
            <option v-if="!goodies" value="anim">Animateur effectue le transport</option>
            <option value="delivery">{{ goodies ? 'Envoi par mondial relay' : 'Livraison' }}</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="transport-type" v-if="selectedType !== null">
      <div v-if="selectedType === 'pickup'" class="transport-pickup">
        <div style="width: 100%">
          <p>Vous avez sélectionné l'option "{{ direction ? 'retour' : 'récupération' }} sur place", un rendez-vous sera
            organisé afin de {{ direction ? 'déposer' : 'chercher' }} le
            matériel.</p>
          <br>
          <p>L'adresse de {{ direction ? 'dépot' : 'retrait' }} est :</p>
          <br>
          <p><b>Salle des Fêtes</b></p>
          <p><b>11 rue de l'église</b></p>
          <p><b>67560 - Rosheim</b></p>
        </div>


        <iframe v-show="!iframeLoading"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1121.1337702312092!2d7.466819901227227!3d48.49394499813229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796ad410240ff11%3A0xa75d29d89b5922dc!2s11%20Rue%20de%20l&#39;%C3%89glise%2C%2067560%20Rosheim!5e1!3m2!1sfr!2sfr!4v1613745480972!5m2!1sfr!2sfr"
                width="70%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0" @load="iframeLoading = false">
        </iframe>

        <div v-if="iframeLoading" style="width: 70%">
          <b-skeleton v-for="index in 12" :key="index"></b-skeleton>
        </div>

      </div>
      <div v-else-if="!goodies && selectedType === 'anim'" class="transport-delivery">

        <div class="transport-delivery-container">

          <div class="sub-container" v-if="!direction">

            <p>Pour estimer le prix du trajet, veuillez entrer l'adresse de votre événement dans les cases
              ci-dessous,
              puis appuyer sur le bouton: <u>Calculer la distance</u>.</p>
            <br>
            <p>La distance sera calculée en prenant compte de l'itinéraire le plus court depuis l'adresse: <br><u><b>11
              rue de l'église - 67560 Rosheim</b></u></p>

            <br>

            <b-field label="N° et nom de rue">
              <b-input v-model="getTransport.name" maxlength="128"></b-input>
            </b-field>

            <b-field label="Code postal et nom de ville">
              <b-input v-model="getTransport.city" maxlength="128"></b-input>
            </b-field>

            <b-field label="Nombre de trajets">
              <b-numberinput controls-alignment="right" v-model="getTransport.trips"></b-numberinput>
            </b-field>

            <b-button type="is-primary is-light"
                      :disabled="getTransport.name.length < 5 || getTransport.city.length < 3 || isLoading"
                      @click="calculateDistance" outlined>Calculer la distance
            </b-button>

          </div>
          <div class="sub-container" v-else>
            <p>Vous avez choisi l'option "Animateur effectue le transport".</p>
            <br>
            <p>Ci-dessous un rappel du prix du transport vers votre événement:</p>
          </div>

          <div class="sub-container" v-if="!direction">

            <p>Le prix du transport sera estimée selon les critères du tableau ci-dessous:</p>

            <br>

            <table class="table is-bordered is-fullwidth">
              <thead>
              <tr>
                <td><b>Description</b></td>
                <td><b>Prix (€)</b></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Distance</td>
                <td>{{ transportData.kmPrice.toFixed(2) }}€ / km</td>
              </tr>
              <tr>
                <td>Temps passé</td>
                <td>{{ transportData.hourPrice.toFixed(2) }}€ / heure</td>
              </tr>
              </tbody>
            </table>

            <br>

            <p>Le calcul est fait en fonction du nombre de km et du temps passé, par exemple pour un transport vers
              Colmar, la distance pour un aller est de 56km et 45 min de trajet.
              On calcule donc <b>2 x (56 x {{ transportData.kmPrice.toFixed(2) }}€ + 45/60 x
                {{ transportData.hourPrice.toFixed(2) }}€) =
                {{ ((transportData.kmPrice * 56 + 45 / 60 * transportData.hourPrice) * 2).toFixed(2) }}€</b></p>

          </div>

        </div>

        <div v-if="getTransport.mapsCallback.found" style="font-size: 20px;">

          <hr v-if="direction" class="solid"/>

          <div style="display: flex; justify-content: center; padding: 15px">
            <quote-transport-callback-table :direction="direction"
                                            :callback="getTransport.mapsCallback"
                                            :transportData="transportData"
                                            :trips="getTransport.trips"
                                            :type="selectedType">
            </quote-transport-callback-table>
          </div>

        </div>

      </div>
      <div v-else-if="!goodies && selectedType === 'delivery'" class="transport-delivery">

        <div class="transport-delivery-container">

          <div class="sub-container">

            <p>Pour estimer un prix de livraison, veuillez entrer l'adresse de votre événement dans les cases
              ci-dessous,
              puis appuyer sur le bouton: <u>Calculer la distance</u>.</p>
            <br>
            <p>La distance sera calculée en prenant compte de l'itinéraire le plus court depuis l'adresse: <br><u><b>11
              rue de l'église - 67560 Rosheim</b></u></p>

            <br>

            <b-field label="N° et nom de rue">
              <b-input v-model="getTransport.name" maxlength="128"></b-input>
            </b-field>

            <b-field label="Code postal et nom de ville">
              <b-input v-model="getTransport.city" maxlength="128"></b-input>
            </b-field>

            <b-button type="is-primary is-light"
                      :disabled="getTransport.name.length < 5 || getTransport.city.length < 3 || isLoading"
                      @click="calculateDistance" outlined>Calculer la distance
            </b-button>

          </div>

          <div class="sub-container">

            <p>Le prix de la livraison sera estimée selon les critères du tableau ci-dessous:</p>

            <br>

            <table class="table is-bordered is-fullwidth">
              <thead>
              <tr>
                <td><b>Description</b></td>
                <td><b>Prix (€)</b></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Distance</td>
                <td>{{ transportData.kmPrice.toFixed(2) }}€ / km</td>
              </tr>
              <tr>
                <td>Temps passé</td>
                <td>{{ transportData.hourPrice.toFixed(2) }}€ / heure</td>
              </tr>
              </tbody>
            </table>

            <br>

            <p>Le calcul est fait en fonction du nombre de km et du temps passé, par exemple pour une livraison à
              Colmar, la distance pour un aller est de 56km et 45 min de trajet.
              On calcule donc <b>2 x (56 x {{ transportData.kmPrice.toFixed(2) }}€ + 45/60 x
                {{ transportData.hourPrice.toFixed(2) }}€) =
                {{ ((transportData.kmPrice * 56 + 45 / 60 * transportData.hourPrice) * 2).toFixed(2) }}€</b></p>

          </div>

        </div>

        <div v-if="getTransport.mapsCallback.found" style="font-size: 20px;">

          <hr class="solid"/>

          <div style="display: flex; justify-content: center; padding: 15px">
            <quote-transport-callback-table :direction="direction"
                                            :callback="getTransport.mapsCallback" :transportData="transportData">
            </quote-transport-callback-table>
          </div>

        </div>

      </div>
      <div v-else-if="goodies && selectedType === 'delivery'" class="transport-delivery">

        <div style="width: 100%">
          <p>Vous avez sélectionné l'option <b>"Envoi par mondial relay"</b>, après avoir envoyé ce devis estimatif,
            <br>
            un email vous sera envoyé afin de vous spécifier le poids du colis.</p>
          <br>
          <p>Une fois le poids du colis connu, il vous faudra acheter une étiquette mondial relay sur internet, en
            <a href="https://www.mondialrelay.fr/envoi-de-colis/" target="_blank">suivant ce lien.</a>
            <br>
            Vous joindrez celle-ci en réponse au mail que vous avez reçu. Le colis sera déposé après réception de l'étiquette.</p>
        </div>

      </div>
    </div>

    <div style="margin-top: 10px;" class="buttons is-centered">
      <b-button v-if="direction" type="is-link is-light is-danger" icon-left="skip-previous" @click="previousStep">
        Retour à l'étape "Transport aller"
      </b-button>

      <b-button v-if="selectedType !== null" type="is-link is-light" icon-left="skip-next" @click="nextStep">Passer à
        l'étape
        "{{ goodies ? 'Infos personnelles' : (direction ? 'Durée' : 'Transport retour') }}"
      </b-button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteTransportCallbackTable from "@/components/quote/QuoteTransportCallbackTable";

export default {
  name: "QuoteTransport",

  components: {QuoteTransportCallbackTable},

  computed: {
    ...mapGetters("data", ["isLoading"]),
    ...mapGetters("quote", ["getTransport"]),

    transportData: {
      get() {
        return this.$store.getters["data/getTransport"]
      }
    }
  },

  props: {
    direction: Boolean, // False: -> : True : <-
    goodies: Boolean
  },

  data() {
    return {
      selectedType: null,
      iframeLoading: true
    }
  },

  mounted() {
    this.selectedType = this.direction ? this.getTransport.retourSelection : this.getTransport.allerSelection
  },

  methods: {
    nextStep() {

      if (!this.goodies && this.selectedType === 'delivery' && !this.getTransport.mapsCallback.found) {
        this.$buefy.snackbar.open({
          message: 'Veuillez entrer votre adresse.',
          type: 'is-danger',
          duration: 5000
        })
        return
      }

      this.$store.commit('quote/setTransportType', {direction: this.direction, value: this.selectedType})
      this.$store.commit('quote/incrementStatus')
      window.scrollTo({top: 0, behavior: 'smooth'});

    },

    previousStep() {
      if (this.selectedType !== null)
        this.$store.commit('quote/setTransportType', {direction: this.direction, value: this.selectedType})
      this.$store.commit('quote/decrementStatus')
      window.scrollTo({top: 0, behavior: 'smooth'});
    },

    calculateDistance() {

      this.$store.dispatch('quote/fetchGoogleData', process.env.VUE_APP_GMAPS_API_KEY)
          .catch((reason) => {

            let message = reason ? 'Impossible de calculer la distance, veuillez réessayer' : 'Veuillez entrer une adresse valide'

            if (reason.message) {
              message = 'Une erreur est survenue: ' + reason.message
            }

            this.$buefy.snackbar.open({
              message: message,
              type: 'is-danger',
              duration: 5000
            })
          })
    },

  },

  watch: {
    direction: {
      // eslint-disable-next-line no-unused-vars
      handler: function (val, oldVal) {
        this.selectedType = val ? this.getTransport.retourSelection : this.getTransport.allerSelection
      }
    }
  }

}
</script>

<style scoped>

.select-transport-type {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.transport-type {
  margin-top: 30px;
  border: 2px solid gray;
  border-radius: 4px;
  padding: 10px;
}

.transport-pickup {
  font-size: 20px;
  display: flex;
  justify-content: space-evenly;
}

.transport-delivery {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.transport-delivery .sub-container {
  width: 100%;
  padding: 20px;
}

.transport-delivery .calculation-container {
  display: flex;
  justify-content: space-evenly;
}

iframe {
  max-height: 100%;
  max-width: 100%;
}

.transport-delivery .transport-delivery-container {
  display: flex;
}

@media screen and (max-width: 767px) {

  .select-transport-type {
    flex-direction: column;
  }

  .transport-delivery .transport-delivery-container {
    flex-direction: column;
  }

  .transport-pickup {
    flex-direction: column;
  }

}

</style>