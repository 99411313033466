<template>
  <div>
    <h1 class="title">Votre devis estimatif est prêt à être généré et envoyé.</h1>
    <h1 class="subtitle">Une copie de ce devis sera envoyée à <a href="mailto:postmaster@fanabriques.fr"
                                                                 target="_blank">postmaster@fanabriques.fr</a>
    </h1>

    <div class="container" style="margin-top: 50px; width: 50%">

      <b-field label="Validez votre mail">
        <b-input type="email" v-model="getUserInfos.sendEmail"></b-input>
      </b-field>

      <b-field label="Ajoutez un message">
        <b-input maxlength="1000" type="textarea" v-model="getUserInfos.message"></b-input>
      </b-field>

    </div>

    <div style="margin-top: 30px" class="captcha">

      <vue-recaptcha ref="recaptcha"
                     @verify="captchaVerified"
                     @error="captchaError"
                     @expired="captchaExpired"
                     :sitekey="getCaptchaSiteKey"
                     :loadRecaptchaScript="true">
      </vue-recaptcha>

      <b-button size="is-large" :disabled="!captchaStatus || getUserInfos.sendEmail.length === 0 || buttonClicked"
                @click="proceedQuoteSend">Générer mon devis
      </b-button>

      <b-button type="is-link is-light is-danger" size="is-small" icon-left="skip-previous" @click="previousStep">
        Retour à l'étape "Infos personnelles"
      </b-button>

    </div>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "QuoteValidation",
  components: {VueRecaptcha},
  computed: {
    ...mapGetters("quote", ["getUserInfos"]),
    ...mapGetters("data", ["getCaptchaSiteKey"])
  },
  data() {
    return {
      captchaStatus: false,
      captchaToken: '',
      buttonClicked: false
    }
  },
  methods: {
    captchaError() {
      this.captchaStatus = false
      this.captchaToken = ''
      this.$refs.recaptcha.reset()

      this.$buefy.snackbar.open({
        message: 'Une erreur est survenue, si l\'erreur se reproduit,<br>veuillez désactiver votre bloqueur de publicité.',
        type: 'is-danger',
        duration: 7500
      })
    },
    captchaVerified(token) {
      this.captchaToken = token
      this.captchaStatus = true
    },
    captchaExpired() {
      this.captchaStatus = false
      this.captchaToken = ''
      this.$refs.recaptcha.reset()
    },
    proceedQuoteSend() {
      if (!this.captchaStatus || this.captchaToken === '' || this.buttonClicked)
        return

      this.buttonClicked = true

      this.$store.dispatch('quote/generateCode', this.captchaToken).catch((e) => {
        this.$buefy.snackbar.open({
          message: 'Une erreur est survenue: ' + e.message,
          type: 'is-danger',
          duration: 5000
        })
        this.captchaExpired()
      }).finally(() => {
        this.buttonClicked = false
      })
    },

    previousStep() {
      this.captchaExpired()

      this.$store.commit('quote/decrementStatus')
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style scoped>
.captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}
</style>
