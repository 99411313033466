<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Coefficients</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <table class="table is-bordered is-fullwidth" style="font-size: 20px">
        <thead>
        <tr>
          <td><b>Nombre de jours</b></td>
          <td><b>Coefficient</b></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="[key, value] of Object.entries(data)" :key="key">
          <td>{{ key }} jour{{ key > 1 ? 's' : '' }}</td>
          <td>x {{ value }}</td>
        </tr>
        <tr>
          <td>Autre</td>
          <td>Demande par mail suite à ce devis</td>
        </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  name: "QuoteDurationCoeffTable",
  props: {
    data: {}
  }
}
</script>

<style scoped>

</style>